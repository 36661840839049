import React from "react";
import * as styles from "./index.module.scss";
import { PageLayout, Typography } from "@components";
import { BackgroundShapes } from "@components/BackgroundShapes/BackgroundShapes";
import cn from "classnames";
import {
	PinkOvals0,
	PinkOvals1,
	PinkSquares2,
	PinkSquares3,
} from "@assets/Background";
import { ArrowLinkButton } from "@components/Button_new/Button";
import { StaticImage } from "gatsby-plugin-image";
import { profile as profiles } from "./profiles/_profile";
import { pinkText } from "@components/Typography/Typography";
import BackgroundFadeContainer from "@components/BackgroundFadeContainer/BackgroundFadeContainer";
import { SectionTitle } from "@components/SectionTitle/SectionTitle";

export default function AboutUsPageV3() {
	return (
		<PageLayout
			seoOptions={{ type: "PREDEFINED", pageName: "about-us" }}
			defaultHeader="light"
			hideOverflowX={true}
		>
			<Hero />
			<Careers />
			<OurVision />
			<MeetOurTeam />
			<CoreValues />
			<BackedBy />
		</PageLayout>
	);
}

function Hero() {
	return (
		<BackgroundFadeContainer type="solidLightPink" withHeaderPadding>
			<BackgroundShapes
				leftShape={{
					shape: <PinkSquares2 />,
					xOffset: -150,
					yOffset: 40,
				}}
				rightShape={{
					shape: <PinkSquares3 />,
					xOffset: -10,
					yOffset: -200,
				}}
			>
				<div className={cn(styles.hero, styles.commonTopBottomMargins)}>
					<Typography
						variant="HERO"
						className={styles.heroHeader}
						color="text-dark"
					>
						{pinkText`Genemod ${"accelerates life sciences"} R&D by improving collaboration for every team`}
					</Typography>
				</div>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}

function Careers() {
	return (
		<BackgroundFadeContainer type="solidBlue">
			<SectionTitle
				title="Our small team is doing big things"
				subtitle="We’re a tight knit team working across the continental US who love finding elegant solutions to complex problems."
				textColor="ui-01"
				titleWidth={728}
				subtitleWidth={680}
				marginTop={120}
				marginTopTablet={120}
				marginTopMobile={80}
				marginBottom={20}
				marginBottomMobile={20}
				marginBottomTablet={20}
			/>
			<div className={styles.arrowBtn}>
				<ArrowLinkButton link="CAREER" color="brand">
					Come work with us
				</ArrowLinkButton>
			</div>
		</BackgroundFadeContainer>
	);
}

const VISION_CARD_CONTENT = [
	{
		title: "Redundancy",
		content:
			"Many experiments are unnecessary duplicates due to a lack of interoperability into past experiments. This limits the exchange and use of data from different sources.",
	},
	{
		title: "Irreproducibility",
		content:
			"Low reproducibility rates within life science research undermine cumulative knowledge production and contribute to both delays and costs of therapeutic drug development.",
	},
	{
		title: "Unusable results",
		content:
			"For example, the problem and cost of misidentified and contaminated cell lines – and their impact on reproducibility – cannot be overestimated.",
	},
];
function OurVision() {
	return (
		<div
			className={cn(
				styles.vision,
				styles.flexCenter,
				styles.commonTopBottomMargins
			)}
		>
			<div className={cn(styles.flexColumn, styles.visionContent)}>
				<Typography variant="HERO" color="text-dark">
					Our vision
				</Typography>
				<StaticImage
					loading="eager"
					src="./images/short-line.svg"
					height={119}
					alt="dotted vertical line"
				/>
				<Typography variant="HEADER1" color="text-dark">
					{pinkText`${"Biotech"} has always led the way in ${"breakthroughs"}`}
				</Typography>
				<Typography color="text-dark" variant="DESCRIPTION">
					But producing therapeutics is complex and requires an
					enormous amount of organization and tracking. In the last
					few years, every team has undergone a digital
					transformation, and every team is accumulating more and more
					data. Research teams that best use their data will create
					the next breakthrough.
				</Typography>
				<Typography
					className={cn(styles.flexColumn, styles.but)}
					variant="HEADER1"
					color="text-dark"
				>
					<StaticImage
						loading="eager"
						src="./images/line-with-bump.svg"
						alt="dotted line with bump"
						className={styles.lineWithBump}
					/>
					<span className={styles.butText}>but</span>
				</Typography>
				<BackgroundShapes
					leftShape={{
						shape: (
							<div style={{ transform: "rotate(30deg)" }}>
								<PinkOvals1 />
							</div>
						),
						xOffset: -440,
						yOffset: -240,
					}}
					rightShape={{
						shape: <PinkOvals0 />,
						xOffset: 440,
						yOffset: 150,
					}}
				/>
				<Typography variant="HEADER1" color="text-dark">
					{pinkText`with ${"more data"}, it becomes harder to manage`}
				</Typography>
				{/* Change of static image between screen width. See the index.module.scss */}
				<StaticImage
					loading="eager"
					className={styles.groupingLines}
					src="./images/ungrouped-line.svg"
					alt="three lines from one"
				/>
				<StaticImage
					loading="eager"
					className={styles.singleLine}
					src="./images/short-line.svg"
					height={119}
					alt="dotted vertical line"
				/>
				{/* ---------------------------------------------------------------------- */}
				<div className={cn(styles.visionCards)}>
					{VISION_CARD_CONTENT.map(({ title, content }) => (
						<OurVisionCard title={title} content={content} />
					))}
				</div>
				{/* Change of static image between screen width. See the index.module.scss */}
				<StaticImage
					loading="eager"
					className={styles.groupingLines}
					src="./images/group-line.svg"
					alt="three lines to one"
				/>
				<StaticImage
					loading="eager"
					className={styles.singleLine}
					src="./images/short-line-white.svg"
					height={119}
					alt="dotted vertical line"
				/>
				{/* ---------------------------------------------------------------------- */}
				<Typography variant="HEADER1" color="ui-01">
					{pinkText`At Genemod, ${"we want to change this"}`}
				</Typography>
				<Typography variant="DESCRIPTION" color="ui-01">
					We bring your team’s work together in one shared space.
					Improve process management by leaving the repetitive stuff
					to us so you can do more of the work you do best.
				</Typography>
				<Typography variant="DESCRIPTION" color="ui-01">
					We believe that the lab of the future won’t run on the same
					technology scientists have been using for decades.
					Researchers now expect flexible, intuitive solutions that
					enable collaboration and creativity.
				</Typography>
				<Typography variant="HEADER4" color="medium-pink">
					That’s why we built Genemod.
				</Typography>
			</div>
			<div className={styles.visionPart2Background}></div>
		</div>
	);
}

type OurVisionCardProps = {
	title: string;
	content: string;
};
function OurVisionCard({ title, content }: OurVisionCardProps) {
	return (
		<div className={styles.visionCard}>
			<div className={styles.centerContent}>
				<Typography variant="HEADER2" color="medium-pink">
					{title}
				</Typography>
				<Typography variant="BODY" center={false}>
					{content}
				</Typography>
			</div>
		</div>
	);
}

function MeetOurTeam() {
	return (
		<div
			className={cn(
				styles.flexColumn,
				styles.meetOurTeam,
				styles.commonTopBottomMargins
			)}
		>
			<div className={styles.centerContent}>
				<Typography variant="HEADER1" color="text-dark">
					Meet our team
				</Typography>
				<Typography
					className={styles.desc}
					variant="DESCRIPTION"
					color="text-dark"
				>
					Our mission is to{" "}
					<span
						className={styles.pink}
						style={{ fontWeight: "bold" }}
					>
						empower teams
					</span>{" "}
					to create the next breakthrough. By caring deeply about each
					other, our customers, and our mission, we’re building a
					company that’s proud to put{" "}
					<span
						className={styles.pink}
						style={{ fontWeight: "bold" }}
					>
						people first
					</span>
					.
				</Typography>
				<TeamCards />
			</div>
		</div>
	);
}

function TeamCards() {
	return (
		<div className={cn(styles.flexCenter, styles.people)}>
			{profiles.map(({ name, title, img }, index) => {
				return (
					<div className={styles.personCard} key={index}>
						<div className={styles.personCardImage}>{img}</div>
						<Typography
							variant="SUBTITLE"
							color="text-dark"
							style={{ marginBottom: 10 }}
						>
							{name}
						</Typography>
						<Typography variant="SUBHEADERDESC" color="cloudy-blue">
							{title}
						</Typography>
					</div>
				);
			})}
		</div>
	);
}

function CoreValues() {
	return (
		<BackgroundFadeContainer type="solidBlue">
			<div
				className={cn(
					// styles.flexColumn,
					styles.coreValues,
					styles.commonTopBottomMargins
				)}
			>
				<Typography variant="HEADER1" center color="ui-01">
					Our core values
				</Typography>
				<Typography
					variant="DESCRIPTION"
					color="ui-01"
					style={{ marginBottom: 24 }}
					center
				>
					These are the values that guide our business, our product
					development, and our brand. As our company continues to
					evolve and grow, these seven values remain constant
				</Typography>
				{/* Change of static image between screen width. See the index.module.scss */}
				<StaticImage
					loading="eager"
					className={styles.coreValuesImage}
					src="./images/core-values.png"
					alt="core values"
				/>
				<StaticImage
					loading="eager"
					className={styles.coreValuesImageMobile}
					src="./images/core-values-mobile.png"
					alt="core values"
				/>
				{/* ---------------------------------------------------------------------- */}
			</div>
		</BackgroundFadeContainer>
	);
}

function BackedBy() {
	return (
		<BackgroundFadeContainer
			type="solidLightPink"
			bodyClass={styles.commonTopBottomMargins}
		>
			<BackgroundShapes
				leftShape={{
					shape: <PinkOvals1 />,
					xOffset: -220,
					yOffset: -320,
				}}
				rightShape={{
					shape: <PinkOvals0 />,
					xOffset: 220,
				}}
			>
				<SectionTitle
					title="We’re backed by world-class investors"
					textColor="marine"
					marginTop={0}
					marginTopTablet={0}
					marginTopMobile={0}
				/>
				<div className={cn(styles.flexCenter, styles.backedByLogos)}>
					<StaticImage
						loading="eager"
						src="../../assets/Investors/defy.png"
						alt="defy logo"
						height={60}
					/>
					<StaticImage
						loading="eager"
						src="../../assets/Investors/Underdog Lab.png"
						alt="underdog labs logo"
						height={81}
					/>
					<StaticImage
						loading="eager"
						src="../../assets/Investors/Canaan.png"
						alt="canaan logo"
						height={48}
					/>
					<StaticImage
						loading="eager"
						src="../../assets/Investors/Omicron Media.png"
						alt="omicron media logo"
						height={48}
					/>
					<StaticImage
						loading="eager"
						src="../../assets/Investors/Unpopular Venture.png"
						alt="unpopular venture logo"
						height={68}
					/>
				</div>
			</BackgroundShapes>
		</BackgroundFadeContainer>
	);
}
